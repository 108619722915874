.top-left-side-nav-banner {
    background-color: #FFFFFF;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    transition: all 0.3s ease;
}

.top-navbar-banner .navbar {
    background-color: #FFF;
    padding: 6px 20px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    transition: all 0.3s ease;
    height: 80px;
}

.side-open-padleft .top-navbar-banner .navbar {
    left: 250px;
}

.side-close-padleft .top-navbar-banner .navbar {
    left: 70px;
}

.top-navbar-banner .toggle-button-1 {
    width: 32px;
    height: 32px;
}

.top-nav-text-1 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-left: 30px;
}

.top-navbar-search-sec .input-group {
    border: 1px solid #EBEBEB;
    background: #F5F5F5;
    border-radius: 12px;
    position: relative;
    /* left: -4vw; */
    height: fit-content;
}

.top-navbar-search-sec .form-control, .top-navbar-search-sec .form-control:focus {
    background: transparent;
    border: none;
    padding: 11px;
    box-shadow: none;
    font-size: 14px;
    width: 20vw !important;
}

.top-navbar-search-sec ::placeholder {
    color: #9E9E9E !important;
}

.top-navbar-search-sec .input-group-text {
    background: transparent;
    border: none;
}

.top-navbar-search-sec .input-group-text img {
    width: 18px;
    height: 18px;
}

.top-navbar-notify-1 {
    width: 18.5px;
    height: auto;
    margin-right: 6vw;
    position: relative;
}

.notify-active::after {
    content: "";
    position: absolute;
    top: -2px;
    left: 12px;
    width: 10px;
    height: 10px;
    background-color: var(--color-1);
    ;
    border: 1px solid var(--border-color-1);
    border-radius: 50%;
    z-index: 9;
}


.pro-drop-img-1 {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.top-navbar-pro-drop-btn {
    padding: 6px 14px;
    border: 0px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
}

.pro-drop-icon-1 {
    color: var(--color-1);
    font-size: 24px;
}

.active-page-root-css {
    background: var(--background-color-1) !important;
    color: #FFF !important;
}

.active-page-root-css .pro-drop-icon-1 {
    color: #FFF;
}

.top-navbar-banner .navbar-toggler {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.mobile-sidenav-close {
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border: 1px solid #63636382;
    border-radius: 8px;
    position: relative;
    left: -10px;
}

.side-nav-logo {
    width: auto;
    height: 60px !important;
}

.list-item-side-logout {
    width: 100%;
    border: 0px;
    color: #F8450B !important;
}

.list-item-side {
    list-style: none;
    background: transparent;
    color: #9E9E9E;
    margin-bottom: 4px;
    text-decoration: none;
    padding: 14px;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-weight: 600;
}

.list-item-top-sec {
    padding: 10px;
}

.side-logout-text-1 {
    color: #717579;
    font-size: 14px;
    text-align: center;
    transition: all 0.3s ease;
}

.side-close-width .side-logout-text-1 {
    font-size: 0px;
    white-space: nowrap;
}

.side-text-1 {
    width: 24px;
    height: auto;
    margin-right: 14px;
}

.side-close-width .side-text-1 {
    margin: 0px;
}

.dash-card-1 {
    background-color: #FEFEFE !important;
    box-shadow: 0px 0px 34px 0px #00000012;
    border: none !important;
    border-radius: 20px !important;
}

.dash-card-2 {
    background-color: #F8F8F8 !important;
    border: none !important;
}

.dash-card-2 .card-body {
    padding: 10px;
}

.dash-head {
    color: var(--color-1);
}

.dash-head-1 {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-1);
}

.dash-head-2 {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color-1);
}

.dash-trans-sec {
    min-height: calc(100vh - 100px);
}

.dash-trans-arrow-btn {
    background-color: transparent;
    border: none;
    font-size: 18px;
}

.dash-trans-arrow-btn-2 {
    background-color: transparent;
    border: none;
    font-size: 15px;
    opacity: 0.8;
}

.dash-trans-btn-1 {
    background-color: #F5F5F5;
    border: 0px;
    Width: 40px;
    height: 36px;
    color: #afafaf;
    font-size: 20px;
    border-radius: 12px;
}

.dash-trans-img-1 {
    width: 38px;
    height: 38px;
}


.dash-trans-img-2 {
    width: 28px;
    height: 28px;
    margin-top: -4px;
}

.dash-trans-img-2-2 {
    margin-top: 10px;
    margin-left: -20px;
}

.dash-trans-txt-1 {
    font-size: 0.9rem;
    font-weight: 400;
}

.dash-trans-txt-1.fw-bold {
    font-weight: 600 !important;
}

.dash-trans-txt-1 .fw-bold {
    font-weight: 600 !important;
}

.dash-trans-txt-2 {
    font-size: 13px;
    opacity: 0.8;
}

.dash-trans-txt-3 {
    font-size: 14px;
    font-weight: 500;
}

.dash-actions-active {
    background-color: #8950DA !important;
    border: 1px solid #DDD0EF !important;
    color: #FFF !important;
}

.dash-actions-active .dash-actions-action-1 {
    color: #FFF !important;
}

.dash-actions-active .arrow-right {
    color: #FFF !important;
}

.dash-actions-btn-1 {
    border: 1px solid #DDD0EF;
    background-color: transparent;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    color: #000;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 13px;
    height: 100%;
    justify-content: space-between;
}

.dash-actions-data-1 {
    position: relative;
    bottom: -8px;
}

.dash-actions-action-1 {
    font-size: 1.8vw;
    color: var(--color-1);
    margin-bottom: 10px;
}

.dash-actions-action-2 {
    font-size: 1.2rem;
    color: var(--color-1);
    margin-right: 6px;
}

.side-close-width .hides-when-close {
    display: none;
}

.dash-swipe-card-sec .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    background-color: transparent;
}

.dash-swipe-card-sec .swiper-slide {
    height: 230px;
}

.dash-swipe-card-1 {
    height: 230px !important;
    color: #FFF !important;
    border: none !important;
    box-shadow: 0px 0px 34px 0px #00000012;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top right;
    background-color: transparent !important;
    border-radius: 10px !important;
    padding: 12px;
}

.dash-swipe-card-1 .card-body {
    padding: 8px;
}

.dash-swipe-card-sec .swiper-pagination-bullet-active {
    background: linear-gradient(180deg, #8950DA 0%, #AF50A7 100%) !important;
}

.dash-swipe-card-sec .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #0006;
}

.dash-swipe-card-sec .swiper-pagination {
    margin-bottom: -14px;
}

.dash-card-img-1 {
    width: 38px;
    height: 38px;
}

.dash-card-txt-1 {
    font-size: 1.1rem;
    font-weight: 600;
}

.dash-card-txt-1-1 {
    font-size: 14px;
}

.dash-card-txt-1-2 {
    font-size: 16px;
    font-weight: 600;
}

.dash-card-img-2 {
    height: 24px;
    width: auto;
}

.dash-card-txt-2 {
    font-size: 2.4rem;
    font-weight: 700;
    margin-left: 6%;
}

.dash-asset-img-2 {
    height: 26px;
    width: 26px;
}

.dash-assets-txt-1 {
    font-size: 14px;
    font-weight: 600;
}

.dash-assets-txt-2 {
    font-size: 13px;
    font-weight: 600;
    color: #9E9E9E;
}

.form-select-monthly {
    box-shadow: none !important;
    border: 1px solid #E3D7F3 !important;
    font-size: 13px !important;
    color: var(--color-1) !important;
    padding: .375rem 1.65rem .375rem .5rem !important;
    background-position: right .35rem center !important;
    --bs-form-select-bg-img: url("../images/dashboard/violet-arrow.png") !important;
    background-size: 12px 12px !important;
}

.send-scan-sec img {
    width: 22px;
    height: 22px;
}

.send-scan-sec {
    border-radius: 50%;
    border: 1px solid #0000001A;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.d-send-card-1 {
    background: #F6F6F6;
    border-radius: 10px;
    padding: 14px 20px;
}

.d-send-input-1 input {
    width: 100%;
    border: 0px;
    font-size: 2.5rem;
    font-weight: 600;
    background-color: transparent;
    background: linear-gradient(180deg, #8950DA 0%, #AF50A7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    outline: none;
}

.d-send-txt-1 {
    font-size: 14px;
    margin-bottom: 0px;
}

.d-send-btn-1 {
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    padding: 16px 0px;
    width: 100%;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-weight: 600;
}

.contact-search-f .form-control, .contact-search-f .input-group-text {
    background-color: transparent;
    box-shadow: none !important;
    border: none;
    font-size: 15px;
    padding: 10px 10px;
}

.contact-search-f .input-group-text svg {
    font-size: 1.6rem;
}

.contact-search-f ::placeholder {
    color: #CACACA !important;
}

.contact-search-f {
    border: 1px solid #E5E0EB;
    border-radius: 50px;
}

.dash-c-txt-1 {
    font-size: 17px;
    margin-bottom: 0px;
}

.dash-c-txt-2 {
    font-size: 14px;
    color: #666666;
    margin-bottom: 0px;
}

.cont-list-height {
    max-height: 340px;
    overflow-y: scroll;
}

.cont-list-height::-webkit-scrollbar-thumb {
    background: transparent !important;
}

.send-money-mod .modal-content {
    background-color: var(--color-1);
    padding: 40px 30px;
    border: none;
    border-radius: 20px;
    position: relative;
}

.send-money-mod .modal-body {
    background-color: #FFF;
    border-radius: 20px;
    position: relative;
}

.modal-body-circle::after {
    content: "";
    position: absolute;
    top: calc(50% - 25px);
    left: -25px;
    width: 50px;
    height: 50px;
    background-color: var(--color-1);
    border-radius: 50%;
    z-index: 99;
}

.modal-body-circle::before {
    content: "";
    position: absolute;
    top: calc(50% - 25px);
    right: -25px;
    width: 50px;
    height: 50px;
    background-color: var(--color-1);
    border-radius: 50%;
    z-index: 99;

}

.send-money-mod .modal-content::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 50px;
    width: calc(100% - 100px);
    height: 2px;
    border-bottom: 1px dashed #b3b3b365;
    z-index: 9;
}

.r-text-dash-1 {
    font-size: 2.5rem;
    font-weight: 600;
    background-color: transparent;
    background: linear-gradient(180deg, #8950DA 0%, #AF50A7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.r-text-dash-1-1 {
    font-size: 2.5rem;
    font-weight: 600;
}

.r-text-dash-2 {
    font-size: 13px;
    color: #B8B8B8;
}

.r-text-dash-3 {
    color: var(--color-1);
}

.r-card-dash-1 {
    border: 1px solid #CFBBEC;
    border-radius: 10px;
    padding: 10px 20px;
}

.r-btn-dash-1 {
    background: var(--background-color-1);
    color: #FFF;
    border-radius: 10px;
    border: none;
    padding: 10px;
}

.add-bank-modal ::placeholder {
    color: #B9BAC8 !important;
}

.add-bank-modal .form-control {
    box-shadow: none !important;
    padding: 10px;
    font-size: 14px;
}

.add-bank-modal .form-control:focus {
    border-color: var(--color-1);
}

.btn-close-red {
    border: 2px solid #FF4B1F;
    border-radius: 4px;
    display: flex;
    width: 28px;
    height: 28px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #FF4B1F;
}

.add-bank-modal .form-check-input, .add-bank-modal .form-check-input:focus {
    border-color: #34C759;
    box-shadow: none !important;
}

.add-bank-modal .form-check-input:checked {
    background-color: #34C759;
    border-color: #34C759;
}

.add-bank-modal .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: 10px;
    margin-left: auto;
}

.dash-goals-card-1 {
    background-color: var(--color-1);
    color: #FFF;
    padding: 30px 10px;
}

.goals-img-1 {
    width: 48px;
    height: 48px;
    border: 2px solid var(--color-1);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.goals-img-2 {
    width: 76px;
    height: 76px;
}

.goals-img-1:nth-child(1) {
    left: 14px;
    position: relative;
    z-index: 0;
}

.goals-img-1:nth-child(2) {
    position: relative;
    z-index: 1;
}

.goals-img-1:nth-child(3) {
    left: -14px;
    position: relative;
    z-index: 1;
}

.goals-txt-1 {
    font-size: 14px;
    width: 65%;
    margin: auto;
    opacity: 0.7;
}

.goals-icon-1 {
    position: relative;
    display: flex;
    top: -14px;
    left: -14px;
    width: 25px;
    height: 25px;
    font-size: 14px;
    background-color: #51564F;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.dash-goals-card-2 {
    padding: 16px;
}

.dash-goals-card-3 {
    box-shadow: 0px 0px 54px 0px #0000000D;
    padding: 16px 8px;
    cursor: pointer;
}

.goals-img-3 {
    width: 48px;
    height: 48px;
    border: 2px solid #EAEAEA;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #FFF;
}

.goals-txt-2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
}

.goals-txt-3 {
    font-size: 13px;
}

.dash-goals-card-3 .progress {
    --bs-progress-height: 7px
}

.goal-divider-1 {
    display: flex;
    border: 1px solid #F6F6F6;
    margin-top: 14px;
    margin-bottom: 14px;
}

.custom-datepicker, .react-datepicker-wrapper {
    width: 100%;
    border: 1px solid #E5E0EB;
    color: #ABA7AF;
    font-size: 14px;
    padding: 10px;
    border-radius: 8px;
    outline: none;
}

.dash-goals-card-2 textarea::placeholder {
    color: #ABA7AF;
}

.dash-goals-card-2 textarea {
    resize: none;
    box-shadow: none !important;
}

.dash-goals-card-2 textarea:focus {
    border-color: #E5E0EB;
}



.goal-modal-1 .modal-content {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border: 0px;
}

.goals-md-img-1 {
    width: 80px;
    height: auto;
    margin-top: -60px;
}

.goal-modal-1 span {
    font-size: 15px;
}

.dash-data-card-1 {
    background: linear-gradient(180deg, #FBF7EF 0%, #F2EBFA 100%);
    padding: 12px 12px;
    border-top: 1px solid #EBDEDE;
    border-left: 1px solid #EBDEDE;
    border-radius: 8px;
}

.dash-data-card-2 {
    background-color: #F8F6FC;
    padding: 12px 12px;
    ;
    border-radius: 8px;
}

.dash-data-txt-1 {
    font-size: 1.4rem;
    font-weight: 600;
}

.dash-data-txt-2 {
    background: #8950DA38;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-1);
    width: fit-content;
    padding: 5px;
    border-radius: 6px;
    font-weight: 600;
}

.dash-data-txt-3 {
    background-color: var(--color-1);
    padding: 4px;
    color: #FFF;
    font-size: 34px;
    border-radius: 8px;
}

.custom-progressbar .CircularProgressbar-text {
    font-weight: 700;
}

.dash-data-txt-4 {
    font-size: 1.8vw;
    font-weight: 600;
    background-color: transparent;
    background: linear-gradient(90deg, #8950DA 0%, #AF50A7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dash-data-btn-1 {
    border: 1px solid var(--color-1);
    border-radius: 4px;
    padding: 2px 12px;
    color: var(--color-1);
}

.data-prog-1 {
    display: flex;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.dash-data-card-3 {
    background: linear-gradient(90deg, #FBF7EF 0%, #F2EBFA 100%);
    padding: 10px;
    border-radius: 10px;
    font-weight: 500;
}

.dash-md-i-1 {
    border: 5px solid #403F42;
    display: flex;
    width: 76px;
    height: 76px;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 50%;
}

.data-modal-1 .modal-content {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    overflow: hidden;

}

.data-month-tabs .nav-pills .nav-link.active {
    background: var(--background-color-1);
}

.data-month-tabs .nav-pills .nav-link:active {
    color: #000;
}

.data-month-tabs .nav-link, .data-month-tabs .nav-link:hover {
    background-color: #F3F3F3;
    color: #000;
    font-size: 13px;
    padding: 5px 0px;
}

.data-month-tabs span {
    font-size: 12px;
    font-weight: 600;
}

.dash-data-card-1-1 {
    background-color: #F7F6F9;
    padding: 10px;
    border-radius: 10px;
}

.dash-data-card-1-2 {
    background-color: #DBC9F4;
    padding: 4px 0px;
    border-radius: 10px;
    text-align: center;
    margin-top: -24px;
    z-index: 999;
    position: relative;
}

.data-card-txt-1 {
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
}

.data-card-txt-2 {
    font-size: 13px;
    font-weight: 600;
}

.data-card-txt-3 {
    font-size: 17px;
    font-weight: 500;
}

.data-card-txt-4 {
    font-size: 14px;
    letter-spacing: 1px;
}

.data-card-img-1 {
    width: 28px;
    height: 28px;
    margin-right: -8px;
}

.data-card-icon-1 {
    color: #45BA56;
    padding: 2px;
    background-color: #FFF;
    border-radius: 50px;
    font-size: 16px;
}

.data-card-txt-5 {
    font-size: 12px;
    opacity: 0.7;
}

.dash-data-card-4 {
    background-color: #F8F6FC;
    padding: 8px;
    border-radius: 10px;
}

.data-card-txt-6 {
    font-size: 14px;
    letter-spacing: 1px;
}

.data-card-txt-7 {
    font-size: 14px;
    font-weight: 600;
}

.dash-acco-btn-1 {
    background-color: #FFF;
    color: var(--color-1);
    border: none;
    border-radius: 50%;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.dash-acco-txt-1 {
    font-size: 2.6rem;
    font-weight: 600;
    background-color: transparent;
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    width: fit-content;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.livemarket-table td {
    border: none;
}

.acc-img-c-1 {
    width: 55px;
    height: 55px;
}

.dash-sum-txt-1 {
    font-size: 14px;
    opacity: 0.8;
}

.dash-sum-txt-2 {
    font-size: 14px;
    font-weight: 600;
}

.acc-send-card-1 {
    background-color: #F7F2F9;
    border-radius: 10px;
    padding: 10px 20px;
}

.acc-s-text-1 {
    font-size: 14px;
    color: #637592;
    margin-bottom: 8px;
}

.acc-s-text-2 {
    font-size: 17px;
}

.acc-s-img-1 {
    width: 24px;
    height: 24px;
}

.acc-send-overrall-sec .form-control-a-send, .acc-send-overrall-sec .form-control-a-send:focus {
    border: none;
    box-shadow: none !important;
    font-size: 24px;
    font-weight: 600;
    height: 2rem;
    background-color: transparent;
    padding: 0px;
}

.swap-icon-s {
    border-radius: 50%;
    background: var(--background-color-1);
    padding: 2px;
    font-size: 38px;
    color: #FFF;
    border: 4px solid #ebebeb;
}

.acc-s-text-3 {
    font-size: 14px;
}

.send-tab-sec-o .nav-pills .nav-link {
    background: #F7F2F9;
    border: 1px solid #8950DA;
    padding: 14px 0px;
    color: #000;
    border-radius: 12px;
    width: 160px;
}

.send-tab-sec-o .nav-pills .nav-link.active {
    background: #F7F2F9;
    border: 1px solid #8950DA;
    color: #000;
    position: relative;
}

.send-tab-sec-o .nav-pills .nav-link.active::after {
    content: "";
    position: absolute;
    background-image: url("../images/account/Check-input.png");
    background-size: 100% 100%;
    width: 26px;
    height: 26px;
    top: 2px;
    right: 2px;
    z-index: 99;
}

.acc-s-img-2 {
    width: 40px;
    height: 40px;
}

.mt-choose-cur-modal .modal-content {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border: 0px;
}

.mt-m-txt-1 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 15px;
}

.mt-m-txt-2 {
    font-size: 14px;
    opacity: 0.6;
}

.mt-m-img-1 {
    width: 40px;
    height: 40px;
}

.mt-m-img-2 {
    width: 24px;
    height: 24px;
}

.side-close-width .side-nav-logo {
    margin-left: 10px;
}

.quick-action-sec .col-p {
    padding-left: 4px;
    padding-right: 4px;
}



.bef-b-login-1 {
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    padding: 6px 20px;
    width: 100%;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-weight: 600;
}

.bef-login-n-sec .nav-link {
    color: #8950DA;
    background: #F8F8F8;
    border-radius: 10px;
    opacity: 1;
    white-space: nowrap;
    font-weight: 500;
    width: fit-content;
    padding: 0.5rem;
}
.bef-login-n-sec .nav-link:focus{
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    width: 100%;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    opacity: 1;
    white-space: nowrap;
}
.bef-login-n-sec .nav-link:hover{
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    width: 100%;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    opacity: 1;
    white-space: nowrap;
}

.bef-login-home-sec {
    padding-top: 80px;
}

.bef-login-home-sec-1 {
    background: var(--background-color-1);
    color: #FFF;
}

.bef-login-home-sec-1-1 {
    background-image: url("../images/home/banner-bg-min.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
}

.bef-l-btn-1:hover {
    background-color: #000000;
    color: #FFF;
}

.bef-login-home-sec-2 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.bef-login-home-sec-foot a {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.bef-login-home-sec-foot {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgba(161, 161, 161, 0.123);
}

.bef-login-two-tab-sec {
    background: var(--background-color-1);
    color: #FFF;
    padding-bottom: 60px;
}

.bef-login-home-sec img {
    width: 70%;
    margin-top: 30px;
}

.bef-signin-h-sec .form-label {
    font-weight: 500 !important;
}

.bef-signin-h-sec .form-check-input {
    box-shadow: none !important;
}

.bef-signin-h-sec .form-check-input:checked {
    background-color: #B5379F;
    border-color: #B5379F;
}
.bef-signin-h-sec .profile-section-one .password-toggle-icon{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
}

.link-s-l-1 {
    font-size: 15px;
    font-weight: 500;
    color: var(--color-1);
    text-decoration: underline !important;
}

.success-icon-1 {
    width: 20px !important;
    height: 20px !important;
    margin-top: 0px !important;
}

.d-send-btn-gr-1 {
    background: var(--background-color-1);
    width: 100%;
    border: none;
    border-radius: 50px;
    padding: 2px;
}

.d-send-btn-gr-1-1 {
    background: #FFF;
    color: var(--color-1);
    padding: 16px 0px;
    border-radius: 50px;
    font-weight: 600;
}

/* Desktop styles */
@media (min-width: 1025px) {
    .bef-login-home-sec-1 {
        clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%, 0 100%, 0 100%, 19% 97%, 30% 95%, 45% 100%, 59% 95%, 69% 100%, 91% 97%);
    }

    .bef-l-btn-1 {
        font-size: 1rem;
        background-color: #FFF;
        border: none;
        padding: 16px 26px;
        border-radius: 50px;
    }

    .bef-l-text-2 {
        font-size: 1.8rem;
        font-weight: 500;
    }

    .bef-l-text-1 {
        font-size: 3.8rem;
        font-weight: 800;
    }

    .bef-login-home-sec-1 .row {
        min-height: 80vh;
        align-items: center;
        justify-content: center;
    }

    .navbar-brand img {
        width: auto;
        height: 60px;
    }

    .mt-sec-link-btn .arrow-right-1 {
        margin-left: 40px !important;
    }

    .mt-choose-cur-modal .modal-dialog {
        margin-left: inherit;
        left: 280px;
        position: absolute;
        bottom: 0;
        width: var(--bs-modal-width);
    }

    .data-modal-1 .modal-dialog {
        margin-left: inherit;
        left: calc(260px + 30%);
        position: absolute;
        bottom: 0;
        width: var(--bs-modal-width);
    }

    .goal-modal-1 .modal-dialog {
        margin-left: inherit;
        left: 260px;
        position: absolute;
        bottom: 0;
        width: var(--bs-modal-width);
    }

    .goal-modal-2 .modal-dialog {
        left: calc(260px + 30%);
    }

    .border-r-1 {
        border-right: 1px solid #00000023;
    }

    .border-r-2 {
        border-right: 2px solid #F6F6F6;
    }

    .dash-actions-txt-1 {
        font-size: 0.9vw;
        font-weight: 600;
    }

    .desk-h-100 {
        height: 100%;
    }



    .side-open-padleft {
        padding-left: 250px !important;
        transition: padding 0.3s ease;
    }

    .side-close-padleft {
        padding-left: 70px !important;
        transition: padding 0.3s ease;
    }

    .side-open-width {
        width: 250px !important;
    }

    .side-close-width {
        width: 70px !important;
    }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1024px) {
    .mt-sec-link-btn .arrow-right-1 {
        margin-left: 40px !important;
    }

    .side-open-padleft {
        padding-left: 250px !important;
        transition: padding 0.3s ease;
    }

    .side-close-padleft {
        padding-left: 70px !important;
        transition: padding 0.3s ease;
    }

    .side-open-width {
        width: 250px !important;
    }

    .side-close-width {
        width: 70px !important;
    }
}

/* Mobile styles */
@media (max-width: 767px) {
    .dash-data-txt-4 {
        font-size: 1.4rem;
    }

    .acc-send-overrall-sec .form-control-a-send {
        font-size: 1.3rem;
    }

    .send-tab-sec-o .nav-pills .nav-link {
        padding: 10px 0px;
        width: 100px;
    }

    .send-tab-sec-o .nav-pills .nav-link.active::after {
        width: 16px;
        height: 16px;
    }

    .acc-s-img-2 {
        width: 30px;
        height: 30px;
    }

    .mt-sec-link-btn {
        width: 100%;
    }

    .dash-swipe-card-1 h5 {
        font-size: 16px;
    }

    .dash-card-txt-1 {
        font-size: 1rem;
        font-weight: 600;
    }

    .dash-card-txt-2 {
        font-size: 1.8rem;
        font-weight: 700;
    }

    .dash-acco-btn-1 {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }

    .dash-card-img-1 {
        width: 30px;
        height: 30px;
    }

    .dash-actions-action-1 {
        font-size: 1.5rem;
    }

    .top-navbar-search-sec .form-control, .top-navbar-search-sec .form-control:focus {
        width: 80% !important;
    }

    .top-nav-text-1 {
        font-size: 16px;
        margin-left: 0px;
    }

    .top-navbar-banner .navbar-toggler {
        font-size: 14px;
        padding: 0px;
    }

    .top-navbar-banner .toggle-button-1 {
        width: 22px;
        height: 22px;
    }

    .top-navbar-banner .navbar {
        background-color: #FFF;
        padding: 12px 0px;
    }

    .side-open-padleft {
        padding-left: 0px !important;
        transition: padding 0.3s ease;
    }

    .side-close-padleft {
        padding-left: 0px !important;
        transition: padding 0.3s ease;
    }

    .side-open-width {
        width: 250px !important;
        left: 0px;
    }

    .side-close-width {
        width: 250px !important;
        left: -250px;
    }
}

/*Both Mobile Tablet styles */
@media (max-width: 1200px) {
    .bef-login-home-sec-1 .row {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .bef-l-btn-1 {
        font-size: 1rem;
        background-color: #FFF;
        border: none;
        padding: 6px 16px;
        border-radius: 50px;
    }

    .bef-l-text-2 {
        font-size: 1.8rem;
        font-weight: 500;
    }

    .bef-l-text-1 {
        font-size: 1.8rem;
        font-weight: 800;
    }

    .navbar-brand img {
        width: auto;
        height: 50px;
    }

    .d-send-input-1 input {
        font-size: 2rem;
    }

    .dash-head-1 {
        font-size: 1.1rem;
    }

    .dash-head-2 {
        font-size: 1rem;
    }

    .dash-acco-txt-1 {
        font-size: 1.6rem;
    }

    .modal-dialog-centered-sm {
        display: flex;
        align-items: center;
        min-height: calc(100% - var(--bs-modal-margin)* 2);
    }

    /* .CircularProgressbar {
        width: 120px !important;
    } */

    .d-grap-img-1 {
        width: 30% !important;
    }

    .cont-list-height img {
        width: 40px;
    }

    .side-close-padleft .top-navbar-banner .navbar {
        left: 0px;
    }

    .side-open-padleft .top-navbar-banner .navbar {
        left: 0px;
        z-index: -9;
    }

    .dash-actions-txt-1 {
        margin-top: 10px;
    }
    .link-s-l-1 {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-1);
        text-decoration: underline !important;
    }
}

.dash-ball-chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    display: none;
}

.dash-ball-chart .apexcharts-tooltip.apexcharts-theme-light {
    background-color: #D8C7F1;
    border: none;
}

.dash-ball-chart .apexcharts-tooltip-y-group {
    display: flex;
    flex-direction: column;
}

.dash-ball-chart .apexcharts-tooltip-text-y-value {
    margin: 0px;
    font-size: 1.2rem;
}

.dash-data-chart .apexcharts-tooltip-marker, .dash-data-chart .apexcharts-tooltip-text-y-label {
    display: none;
}

.dash-data-chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    display: none;
}

.dash-data-chart .apexcharts-tooltip.apexcharts-theme-light {
    background-color: #FFF;
    border: none;
}

.dash-data-chart .apexcharts-tooltip-y-group {
    display: flex;
    flex-direction: column;
}

.dash-data-chart .apexcharts-tooltip-text-y-value {
    margin: 0px;
    font-size: 1.2rem;
}

.d-swipe-btn-1 {
    background-color: #DCD0ED;
    border-radius: 50px;
    width: 100%;
    color: #FFF;
    border: none;
    font-weight: 600;
    height: 56px;
    position: relative;
}

.d-swipe-btn-1-1 {
    background: var(--color-1);
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    border-radius: 50px;
    position: absolute;
    left: 20px;
    transition: left 0.3s;
    transform: translateY(-50%);
    top: 50%;
}


.d-swipe-btn-1-2 {
    color: var(--color-1);
    font-weight: bold;
}