@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-1: #8950DA;
  --color-2: #A287FF;
  --border-color-1: #BB9EE5;
  --background-color-1: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);

  --default-color-1: #000;
  --default-background-color-1: #F6F6F6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif !important;
  color: var(--default-color-1) !important;
  background-color: var(--default-background-color-1) !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-1);
  border-radius: 10px;
}


a {
  text-decoration: none !important;
}

.fc-y {
  color: var(--color-1);
}

.fc-g {
  color: #1FCB4F;
}

.fc-r {
  color: #F46D22;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.top-web-sec {
  padding-top: 100px;
}

.cur-point {
  cursor: pointer;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.modal.slide-in-from-right .modal-dialog {
  animation: slideInFromRight 0.5s forwards;
}

@keyframes slideInFromDown {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal.slide-in-from-down .modal-dialog {
  animation: slideInFromDown 0.5s forwards;
}
