/* ==========Karthick ============= */
/* =====Desktop ======== */
@media (min-width: 992px) {
    .profile-section-one .password-container {
        position: relative;
        width: 80%;
    }

    .profile-section-one .done-css {
        background: linear-gradient(90.9deg, #B5379F 3.03%, #69678a 94.33%);
        border: none;
        border-radius: 10px;
        color: white;
        width: 80%;
        font-size: 14px;
        padding: 10px;
    }

    .swap-section .nav-pills .nav-link {
        --bs-nav-link-padding-x: 60px;
    }

    .accountsection .video-sec {
        width: 75%;
    }

    .Useraccountform .text-one {
        font-size: 14px;
    }

    .Useraccountform .form-section {
        background-color: white;
        border-radius: 15px;
        height: 100%;
        overflow-y: scroll;
    }

    .admin .form-control {
        width: 100%;
    }
    .swap-section .done-css {
        background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
        border: none;
        border-radius: 10px;
        color: white;
        width: 40%;
        font-size: 14px;
        padding: 10px;
    }
}

/* =====Desktop ======== */
/* =====Mobile ======= */
@media (max-width: 991px) {
    .profile-section-one .password-container {
        position: relative;
        width: 100%;
    }

    .profile-section-one .done-css {
        background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
        border: none;
        border-radius: 10px;
        color: white;
        width: 100%;
        font-size: 14px;
        padding: 10px;
    }

    .swap-section .nav-pills .nav-link {
        --bs-nav-link-padding-x: 50px;
    }

    .accountsection .video-sec {
        width: auto
    }

    .accountsection .opt-box {
        height: 50px !important;
        min-width: 0px !important;
    }

    .Useraccountform .text-one {
        font-size: 12px;
    }

    .Useraccountform .form-section {
        background-color: white;
        border-radius: 15px;
        height: auto;
        overflow-y: scroll;
    }

    .admin .form-control {
        width: 100%;
    }
    .swap-section .done-css {
        background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
        border: none;
        border-radius: 10px;
        color: white;
        width: 100%;
        font-size: 14px;
        padding: 10px;
    }
}

/* =====Mobile ======= */
.profile-section .profile-section-one .profile-col {
    background: #FEFEFE;
    margin: 10px;
    border-radius: 15px;
}

.profile-section-one .pro-pic {
    width: 100px;
    height: 100px;
}

.profile-section-one .user-name {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    color: var(--color-1);
}

.profile-section-one .user-id {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.06px;
    color: #00000099;
}

.profile-section-one .edit-profile {
    border: 1px solid #84818a33;
    color: #84818A;
    width: auto;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
}

.profile-section-one .pro-left {
    border-right: 1px solid rgba(128, 128, 128, 0.253);
}

.profile-section-one .nav-pills .nav-link.active,
.profile-section-one .nav-pills .show>.nav-link {
    color: var(--color-1);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    background-color: transparent;
}

.profile-section-one .nav-link {
    font-weight: 700;
    color: #202020;
    padding: 10px 0px;
}

.profile-section-one .form-label {
    font-weight: 300;
    font-size: 14px;
}

.profile-section-one .password-input {
    width: 100%;
    padding-right: 40px;
    /* Space for the icon */
}

.profile-section-one .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 70%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 9;
}

.profile-section-one .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    outline: 0;
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.342);
}

.profile-section-one input::placeholder {
    font-size: 13px;
    color: rgb(161, 161, 161);
}

.profile-section-one .nav-link:hover {
    color: var(--color-1);
}

.profile-section-one .react-tel-input .flag-dropdown {
    border: 1px solid #e3e3e3;
    border-right: none;
    border-radius: 0.285rem;
    background-color: transparent;
}

.profile-section-one .react-tel-input .form-control {
    padding-left: 40px;
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    height: 40px;
}

.profile-section-one .react-tel-input .form-control:focus {
    /* border-color: rgba(0, 0, 0, 0.342); */
}

.profile-section-one .react-tel-input .selected-flag:hover,
.profile-section-one .react-tel-input .selected-flag:focus,
.profile-section-one .react-tel-input .selected-flag:active {}


@keyframes zoomOut {
    0% {
        transform: scale(1.05);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.profile-section-one .tab-pane {
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

.profile-section-one .tab-pane.show.active {
    animation-name: zoomOut;
}

.profile-section-one .tab-pane {
    transition: all 0.1s ease-in-out;
}

.cryto-section .total-heading {
    font-size: 1.3rem;
    font-weight: 500;
}

.cryto-section .bal-text {
    font-size: 13px;
    font-weight: 400;
    color: #4F4F4F;
}

.cryto-section .avail-bal {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
}

.cryto-section .value-pic {}

.cryto-section .bal-text-two {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}

.cryto-section .link-btn {
    background: #ffffff;
    padding: 16px 16px;
    color: var(--color-1);
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #D7CEE3;
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.cryto-section .arrow-right {
    color: var(--color-1);
    font-size: 20px;
    margin-left: 14px;
}

.cryto-section .exchange .form-label {
    font-size: 10px;
    font-weight: 400;
    color: #595656;
    margin-left: 10px;
    margin-bottom: 0px;
}

.cryto-section .exchange .form-control {
    border: 1px solid #333333;
    border-radius: 10px;
    padding: 8px;
    width: 90%;
}

.cryto-section .exchange .form-control:focus {
    box-shadow: none;
}

.cryto-section .exchange .form-control::placeholder {
    font-size: 10px;
    font-weight: 400;
    color: #595656;
}

.cryto-section .exchange .from-btn {
    background: #F5F4F4;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.cryto-section .exchange .swap-icon {
    border-radius: 50%;
    border: 1px solid grey;
    padding: 2px;
    font-size: 24px;
}

.cryto-section .exchange .fees-sec {
    background: #F5F4F4;
    padding: 10px;
    border-radius: 8px;
}

.cryto-section .exchange .fees-text {
    font-size: 12px;
    font-weight: 400;
    color: #595656;
}

.cryto-section .exchange .done-css {
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    padding: 16px 0px;
    width: 100%;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-weight: 600;
}

.cryto-section .live-heading {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-1);
}

.cryto-section .table-size {
    overflow: auto;
}

.cryto-section .livemarket-table {
    width: 100%;
}

.livemarket-table tr {}

.livemarket-table td {
    padding: 15px 50px 15px 0px;
    vertical-align: middle;
    white-space: nowrap;
}

.livemarket-table td:first-child {
    width: 30%;
    /* First column */
}

.livemarket-table td:nth-child(2) {
    width: 30%;
    /* Second column */
}

.livemarket-table td:nth-child(3) {
    width: 30%;
    /* Third column */
}

.livemarket-table td:last-child {
    width: 10%;
    /* Fourth column */
}


.livemarket-table .text-one {
    font-size: 15px;
    font-weight: 600;
    color: #1A1A1A;
}

.livemarket-table .text-two {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: #9E9E9E;
}

.livemarket-table .text-three {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: #9E9E9E;
}

.livemarket-table .text-four {
    font-size: 13px;
    font-weight: 500;
}

.livemarket-table .fc-g {
    color: #1FCB4F;
}

.livemarket-table .text-five {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #1A1A1A;
}

.livemarket-table .fc-r {
    color: #F46D22;
}

.livemarket-table .graph-size {}

/* Optional: Customize scrollbar appearance */
.table-size::-webkit-scrollbar {
    width: 12px;
    /* Width of the scrollbar */
    height: 4px;
}

.transaction .text-one {
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
}

.transaction .text-two {
    font-size: 13px;
    font-weight: 400;
    color: #9E9E9E;
}


.cryptoinner-section-one .graph-section {
    background: #FFFFFF;
}

.graph-section .text-one {
    font-size: 15px;
    color: #191919;

}

.graph-section .text-two {
    font-size: 12px;
    color: grey;
}

.graph-section .text-three {
    font-size: 14px;
}

.fc-b {
    color: #31B8C9;
}

.graph-section .text-four {
    font-size: 12px;
}


.graph-section .calendar-container {
    position: relative;
    display: inline-block;
}

.graph-section .calendar-icon-button {
    margin: 3px;
    padding: 2px 10px;
    border: none;
    border-radius: 5px;
    background: #E8E8E8;
}

.graph-section .view-buttons {
    margin-top: 10px;
}

.graph-section .view-buttons button {
    margin: 3px;
    padding: 6px 8px;
    font-size: 11px;
    border: none;
    border-radius: 5px;
    background: #E8E8E8;
}

.graph-section .react-calendar {
    position: absolute;
    top: 35px;
    z-index: 99;
}

.cryptoinner-section-one .swap-section {
    background: #FFFFFF;
}

.swap-section .nav-pills .nav-item {
    background: #E8E8E8;
    border-radius: 5px;
}


.swap-section .nav-pills .nav-link.active,
.swap-section .nav-pills .show>.nav-link {
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
}

.swap-section .nav-link {
    color: #FF4B1F;
}

.swap-section .text-one {
    font-size: 13px;
    color: #191919;
}

.swap-section .text-two {
    font-size: 11px;
    color: grey;
}

.swap-section .border-bottom-css {
    border-bottom: 1px solid rgb(241 241 241);
}

.swap-section .position-relative {
    position: relative;
}

.swap-section .position-absolute {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}


.swap-section .form-control {
    border-radius: 10px;
    padding: 12px;
    border: 1px solid #ECE6E6;
}

.swap-section .form-control:focus {
    border: 1px solid #ECE6E6;
    box-shadow: none;
}

.swap-section .from-img {
    background: #292929;
    padding: 4px;
    border-radius: 5px;
}

.swap-section .custom-placeholder-input {
    color: black;
 
    
}

.swap-section .custom-placeholder {
    position: absolute;
    top: 38%;
    right: 39%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #B8B8B8;
    font-size: 12px;
    line-height: 1rem;
    white-space: wrap;
}

.swap-section .custom-placeholder-input:focus+.custom-placeholder,
.swap-section .custom-placeholder-input:not(:placeholder-shown)+.custom-placeholder {
    display: none;
}

.swap-section .swap-icon {
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    border: none;
    border-radius: 50%;
    color: white;
}

.swap-section .text-three {
    font-size: 12px;
    font-weight: 300;
}



.trade-section .nav-pills .nav-link.active,
.trade-section .nav-pills .show>.nav-link {
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;

}

.trade-section .nav-link {
    color: black;
    font-size: 12px;
    font-weight: 700;
}

.cryptoinner-section-one .cryto-table {
    overflow: auto;
    background: #FFFFFF;
}

.cryptoinner-section-one .cryto-table-section {
    width: 100%;
    background: #FFFFFF;
}

.cryptoinner-section-one .cryto-table-section th {
    font-size: 10px;
    font-weight: 300;
    color: #191919;
    line-height: 18.38px;
    padding: 10px;
}

.cryptoinner-section-one .cryto-table-section td {
    padding: 10px;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 400;
    line-height: 18.38px;
}

.cryptoinner-section-one .cryto-table-section td:first-child {
    width: 15%;
}

.cryptoinner-section-one .cryto-table-section td:nth-child(2) {
    width: 15%;
}

.cryptoinner-section-one .cryto-table-section td:nth-child(3) {
    width: 10%;
}

.cryptoinner-section-one .cryto-table-section td:nth-child(4) {
    width: 10%;
}

.cryptoinner-section-one .cryto-table-section td:nth-child(5) {
    width: 10%;
}

.cryptoinner-section-one .cryto-table-section td:nth-child(6) {
    width: 15%;
}

.cryptoinner-section-one .cryto-table-section td:last-child {
    width: 15%;
}

.buy-modal .modal-content {
    background-color: var(--color-1);
    padding: 40px 30px;
    border: none;
    border-radius: 20px;
    position: relative;
}

.buy-modal .modal-body {
    background-color: #FFF;
    border-radius: 20px;
    position: relative;
}


.cryto-table .crypto-table-options {
    display: flex;
    align-items: center;
    gap: 0px;
    margin: 10px 10px 10px 0px;
    white-space: nowrap;
}

.cryto-table .option-btn {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    line-height: 18.38px;
}

.cryto-table .search-input {
    padding-left: 10px;
    width: 200px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0.5px solid #E6E1EE
}

.cryto-table .search-input::placeholder {
    font-size: 10px;
}

.crypto-table-options .search-btn {
    border: none;
    background: #D9D1E4;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 16px;
}

.card-section .mycard-section {
    background: #ffffff;
}

.mycard-section h6 {
    color: var(--color-1);
}

.mycard-section .card {
    --bs-card-spacer-y: 2.5rem;
}

/* .mycard-section .card-body {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
} */

.mycard-section .addcard-btn {
    background: var(--color-1);
    color: white;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    --bs-btn-padding-x: 5rem;
    --bs-btn-padding-y: 16px;
}

.mycard-section .addcard-btn:hover {
    background: var(--color-1);
    color: #FFF;
}

.mycard-section .offcanvas .form-control::placeholder {
    font-size: 12px;
    color: #C3C7E5;
}

.mycard-section .offcanvas .form-control:focus {
    border-color: var(--color-1);
    box-shadow: none;
}

.mycard-section .offcanvas .form-select {}

.mycard-section .offcanvas .form-select:focus {
    border-color: var(--color-1);
    box-shadow: none;
}

.card-section .card-transaction {
    background: #ffffff;
}

.card-transaction h5 {
    color: var(--color-1);
}

.card-section .transaction-section-table {
    overflow: auto;
}

.card-section .transaction-table {
    width: 100%;
}

.card-section .transaction-table th {
    font-size: 10px;
    font-weight: 400;
    color: #333333;
    opacity: 0.7;
    padding: 15px;
}

.card-section .transaction-table td {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    padding: 18px 15px;
    white-space: nowrap;
}

/* .card-section .transaction-table td:first-child{
    width: 20%;
}
.card-section .transaction-table td:nth-child(2){
    width: 20%;
}
.card-section .transaction-table td:nth-child(3){
    width: 20%;
}
.card-section .transaction-table td:nth-child(5){
    width: 20%;
}
.card-section .transaction-table td:last-child{
    width: 20%;
} */
.card-transaction .form-select:focus {
    box-shadow: none;
}


/* ====KYC ============ */
.Kycdocuments .card-body {
    /* height: 525px;
    overflow: scroll; */
}

.Kycdocuments {
    padding-top: 75px;
}

.kycdoc-section .form-label {
    font-weight: 500;
    font-size: 14px;
}

.kycdoc-section .form-select:focus {
    border-color: #dee2e6;
    outline: 0;
    box-shadow: none;
}

.kycdoc-section input::placeholder {
    font-size: 13px;
    color: rgb(161, 161, 161);
}

.kycdoc-section .custom-select option {
    font-size: 14px;
    /* Font size for all options */
    color: #333;
    /* Font color for options */
}

.kycdoc-section .custom-select option:first-child {
    font-size: 16px;
    /* Font size for the first (placeholder) option */
    color: #999;
    /* Lighter color for the placeholder */
}

.kycdoc-section .form-control:focus {
    box-shadow: none;
    border-color: #dee2e6;
}

.kycdoc-section .option-css {
    font-size: 12px;
    color: var(--color-1);
}

.kycdoc-section .form-check-input {
    width: 1.5em;
    height: 1.5em;
    border-color: #333;
}

.kycdoc-section .text-one {
    font-weight: 500;
    font-size: 16px;
}

.kycdoc-section .doc-css {
    width: 25px;
    height: 30px;
    margin: 0px;
}

.kycdoc-section .doc-section {
    background: #2828280D;
    border-radius: 5px;
    padding: 10px 4px 10px 10px;
}

.kycdoc-section .doc-border {
    border-bottom: 0.5px solid #d9d9d981;
}

.kycdoc-section .en-css {
    width: 100px;
    height: 100px;
}

.kycdoc-section .doc-section-two {
    background: #DDE2E7;
    border-radius: 10px;
    padding: 10px 4px 10px 10px;
}

/* ====KYC ============ */

/* =====Account-section ========== */
.accountsection {
    padding-top: 50px;
}

.accountsection .form-label {
    font-weight: 500;
    font-size: 14px;
}

.accountsection img {
    width: 100%;
    margin: 0;
}

.accountsection .video-img {
    width: 50px;
    height: 50px;
}

.accountsection .video-sec {
    border: 1px solid #8950DA;
    border-radius: 10px;
}

.accountsection .opt-btn {
    font-size: 11px;
    font-weight: 600;
    padding: 11px;
    border: none;
    white-space: nowrap;
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    color: white;
}

.accountsection .modal-dialog {
    --bs-modal-width: 600px;
}

.accountsection .d-send-btn-2 {
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    padding: 16px 0px;
    width: 75%;
    color: #FFF;
    border: none;
    border-radius: 10px;
    font-weight: 600;
}

/* =====Account-section ========== */

/* =====Useraccountform ============ */

.Useraccountform .business-subtitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color-1);
}

.Useraccountform .option-css {
    font-size: 13px;
    color: var(--color-1);
}

.Useraccountform .form-control:focus {
    box-shadow: none;
}

.Useraccountform .form-label {
    font-weight: 500;
    font-size: 14px;
}

.Useraccountform .signature-canvas {
    border: 1px solid darkgrey;
    border-radius: 5px;
    width: 100%;
    height: 50px;
}

/* =====Useraccountform ============ */
.rq-msg {
    font-size: 13px;
    font-weight: 500;
}

.bef-signin-h-sec .modal-dialog {
    --bs-modal-width: 700px;
}

.resume-que {
    font-size: 50px;
    margin: 10px 0px;
}

.error-page .text {
    color: #f5f5f5;
    font-size: 5rem !important;
    font-weight: 700;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px #10101066, 1px 22px 10px #10101033, 1px 25px 35px #10101033, 1px 30px 60px #10101066;
    text-transform: uppercase;
}

.load-page .loader-img {
    width: auto;
    height: auto;
}

.load-page .div-parent {
    perspective: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div-parent .square {
    transform: rotateY(45deg);
    animation: rotateAnimation 4s linear infinite;
}

@keyframes rotateAnimation {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
        /* End at 225 degrees without going back */
    }
}



.business input[type=number]::-webkit-outer-spin-button,
.business input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button;
    display: block;
    opacity: 1;
}

.business input[type=number] {
    -moz-appearance: textfield;
    /* Removes the spinner in Firefox */
}

.sumsub .modal-dialog {
    --bs-modal-width: 75%;
}

.sumsub .modal-content {
    height: 600px;
}


/* ===== */
.trading-page-section .progress-bar-bg-color .flex-row {
    align-items: center !important;
    height: 30px !important;
    vertical-align: middle !important;
    /* padding-left: 10px;
    padding-right: 10px; */
    position: relative;
}

.trading-page-section .progress-table-1 {
    width: 30%;
    /* text-align: center; */
}

.trading-page-section .progress-table-2 {
    width: 40%;
    text-align: center;
}

.trading-page-section .progress-table-3 {
    width: 30%;
    text-align: center;
}

.trading-page-section .orderbook-text-1 {
    align-items: center;
    color: #727581;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .015em;
    padding-bottom: 0 !important;
}

.trading-page-section .trading-table-left-section {
    height: 150px !important;
    overflow: auto;
}

.trading-page-section .orderbook-text-2 {
    font-size: 75%;
    font-weight: 600;
    letter-spacing: .26px;
    line-height: 1.5;
    margin: 0;
    color: #727581;
}

.trading-page-section .trade-text-5 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: .045em;
    margin-bottom: 0;
}

.trading-page-section .orderbook-text-2-12 {
    font-size: 11px;
}

/* ===== */


/* ==========Karthick ============= */

/* ========== AMIRTHA ============= */
.deposit-section .dep-btn{
    background: #ffffff;
    padding: 5px 10px;
    color: var(--color-1);
    font-size: 14px;
    font-weight: 500;
    border: 2px solid #D7CEE3;
    border-radius: 12px;
    display: flex;
    align-items: center;
}

.deposit-section .deposit-col{
    background: #FEFEFE;
    margin: 10px;
    border-radius: 15px;
}

.deposit-section .text-one{
    font-size: 15px;
    font-weight: 600;
    color: black;

}
.deposit-section .text-two{
    font-size: 15px;
    font-weight: 500;
    color: #646464;
}

.deposit-section .list-icon{
   color: #B5379F;
   font-size: 1.5rem;
}

.deposit-section .dep-submit-btn{
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    padding: 10px;
}

.deposit-section .deposit-img{
    width: 33px;
    height: auto
}

.deposit-section .tittle-tx{
    font-size: 17px;
    font-weight: 600;
    color: var(--color-1);
}

.deposit-section .tittle-tx1{
    font-size: 1.3rem;
    font-weight: 600;
    background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
    width: fit-content;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.deposit-section .modal-header{
    border-bottom: none;
}

.deposit-section .input-div{
    border:1px solid #dee2e6;
    padding: 6px 10px;
    background: transparent;
}


.deposit-section .list-div:hover{
background: #f4f4f4;
}

.deposit-section .form-control{
    padding: 8px;
    border-radius: 0px;
}

.deposit-section .form-control:focus{
    box-shadow: none;
    border-color: #dee2e6;
}

 .crypto-select .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color:  var(--color-1) !important;
}

.crypto-select .dropdown-menu {
    color: var(--color-1) !important;
    text-decoration: none;
    background-color:  white !important;
    border-color: #D7CEE3;
    padding: 10px 10px;
    --bs-dropdown-min-width:100px;
}
/* ========== AMIRTHA ============= */