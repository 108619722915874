/* <============pandi============> */

.epic-ctn-12 {
  height: 200px !important;
  background: transparent !important;
  border: none !important;
}

.epic-ctn-14 {
  border-top: 1px solid rgba(0, 0, 0, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.epic-ctn-20 {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.epic-ctn-25 {
  color: rgba(137, 80, 218, 1) !important;
  border: 1px solid rgba(137, 80, 218, 1) !important;
}

.epic-ctn-09 {
  height: 210px;
}

.epic-ctn-28 {
  font-size: 16px;
  color: rgba(137, 80, 218, 1);
}

.epic-ctn-29 {
  font-size: 40px;
  color: rgba(137, 80, 218, 1);
}

.epic-ctn-30 {
  font-size: 13px;
}

.epic-ctn-31 {
  height: 100px;
}

.epic-ctn-32 {
  width: 100%;
}

.epic-ctn-32 {
  font-size: 12px;
  background: rgba(137, 80, 218, 1);
  border: 1px solid rgba(137, 80, 218, 1);
  color: white;
  border-radius: 8px;
}

.epic-ctn-33 {
  width: 100%;
  color: rgba(137, 80, 218, 1) !important;
  border: 1px solid rgba(137, 80, 218, 1) !important;
  font-size: 12px;
  border-radius: 8px;
}

.secon-col-epic-01 {
  height: 300px;
}

.help-support-01 {
  color: rgba(137, 80, 218, 1);
  font-size: 15px;
}

.rempic-office-img-01 {
  width: 115px;
}

.rempic-img-background {
  background: white;
  border-radius: 10px;
}

.card-rempic-office-ctn {
  font-size: 12px;
}

.help-support-01 {
  font-size: 16px;
}

.rempic-united-states-01 {
  font-size: 16px;
  color: rgba(137, 80, 218, 1);
}

.epic-ctn-06 {
  background-color: white;
  border-radius: 10px;
}

.rempic-selector-01 {
  width: 95px !important;
}

.epic-monthly-001 {
  font-size: 12px !important;
}



/* <============================> */

/* Desktop styles */
@media (min-width: 1025px) {


  .epic-chart-sec-01 {
    /* background:white; */
    border-radius: 8px;

  }

  .epic-rounds-up {
    color: rgba(137, 80, 218, 1);
    font-size: 18px;
  }

  .epic-rounds-help {
    color: rgba(137, 80, 218, 1);
    font-size: 18px;
  }

  .rnds-up-money {
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rnds-up-money .css-wmy1p7-ReactDropdownSelect {
    border: 1px solid grey !important;
    border-radius: 6px;
  }

  .rnds-up-money-box {
    background: #eae3e3bd;
  }

  .add-to-spend-01 {
    border-top: 1px solid #eae3e3bd;
    border-bottom: 1px solid #eae3e3bd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
  }

  .new-epic-cash-btn {
    background: transparent;
    border: 1px solid rgba(137, 80, 218, 1);
    color: rgba(137, 80, 218, 1);
    border-radius: 8px;
    padding: 10px;
  }

  .epic-ref-ctn {
    color: rgba(137, 80, 218, 1);
  }

  .epic-ref-ctn-num {
    font-size: 50px;
    color: rgba(137, 80, 218, 1);
  }

  .epic-share-full-ctn {
    font-size: 12px;
    color: #786767bd;
    text-align: center;
    padding: 10px;
  }

  .epic-invite-frd-02 {
    background: var(--background-color-1) !important;
    padding: 12px;
    border: 0;
    border-radius: 6px;
    color: white;
    font-size: 14px;
  }

  .epic-invite-frd-03 {
    /* background: var(--background-color-1) !important; */
    padding: 12px;
    border: 0;
    border-radius: 6px;
    color: rgba(137, 80, 218, 1);
    font-size: 14px;
    background: transparent;
    border: 1px solid rgba(137, 80, 218, 1);
  }

  .epic-tot-rempic-office {
    background: #eae3e3bd;
    border-radius: 6px;
  }

  .united-state-ctn-01 {
    color: rgba(137, 80, 218, 1);
    font-size: 15px;
  }

  .epic-tkn-num-01 {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .epic-tkn-num-02 {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .epic-fraud-01 {
    color: #554848bd;
    font-size: 14px;
  }

  .epic-fraud-new-child {
    color: black;
  }

  .epic-customer-care-01 {
    font-size: 12px;
    color: #554848bd;
  }

  .full-mail-ctn-01 {
    border-top: 1px solid #eae3e3bd;

  }

  .new-logo-rempic-01 {
    width: 100px;
    height: 70px;
    object-fit: contain;
  }

  .new {
    position: absolute;
    top: 65px;
  }

  .epic-usa-office {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }

  .epic-tkn-num-01 {
    font-size: 13px;
  }

  .epic-tkn-full-num-01 {
    font-size: 13px;
  }

  .epic-fraud-01 {
    font-size: 13px;
  }

  .new-remic-nav {
    display: flex !important;
    justify-content: space-around !important;
  }

  .epic-tab-mail-01 {
    font-size: 13px;
  }

  .new-united-ss {
    display: flex;
    align-items: center !important;
  }

  .epic-tab-mail-num-00 {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
    /* border-top:1px solid grey; */
  }

  .epic-united-sec-01 {
    border-top: 1px solid #eae3e3bd;
  }

  .epic-helps-support {
    color:
      rgba(137, 80, 218, 1);
    font-size: 18px;
  }



  .new-epic-usa-off,
  .new-epic-uk-off {
    font-size: 12px;
    text-align: center;
  }

  .rempic-box-office {
    background: #f6f2f2;
    border-radius: 8px;
  }

  .united-office {
    color:
      rgba(137, 80, 218, 1);
    font-size: 13px;
  }

  .epic-united-states-whl-001 .nav-pills .nav-link {
    border-radius: 10px;
    padding: 2px !important;
  }

  .epic-united-states-whl-001 .nav-pills .nav-link.active {
    background: var(--background-color-1);
  }

  .epic-nav-link-2 {
    background-color: #FFF;
    border-radius: 9px;
    padding: 6px;

  }

  .epic-fraud-ctn {
    color: grey;
  }

  .epic-chart-sec-01 .form-check-input:checked {
    background-color: rgba(137, 80, 218, 1) !important;
    padding: 9px;
    box-shadow: none !important;
    border: none !important;
  }

  .epic-chart-sec-01 .form-check-input:checked {

    padding: 9px;
    box-shadow: none !important;
    border: none !important;
  }

  .multiply-drop-btn {
    width: 100px;
    font-size: 10px !important;
  }

  .new-multiply-btn {
    width: 100px !important;
    background: transparent !important;
    border: 1px solid grey !important;
    font-size: 11px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
    color: grey !important;

  }

  .epic-chart-rounded-01 {
    background: #f2ebeb !important;
  }

  .rnds-up-money-box {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }

  .epic-chart-rounded-01 {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .epic-total-points-54 {
    position: absolute;
    top: 38%;
    left: 25%;
    font-size: 10px;
    text-align: center;
    color: grey;
  }

  .epic-total-number {
    color: var(--color-1);
    font-size: 13px;
  }

  .epic-form-select-01 {
    font-size: 12px !important;
    width: 100px !important;
    border: 1px solid #978c8c !important;
    color: black !important;
    box-shadow: none !important;
  }

  .epic-form-select-01 {
    background-color: #eae3e3bd !important;
  }

  .epic-total-points-540 {
    position: absolute;
    top: 0px !important;
  }

  .epic-chart-sec-00 .apexcharts-legend {
    position: absolute;
    left: auto;
    top: 24px;
    right: 6% !important;
  }

  .epic-total-points-54 {
    position: absolute;
    top: 38%;
    left: 25%;
    font-size: 10px;
    text-align: center;
    color: grey;
  }

  .epic-total-points-5 {
    margin-top: -30px;
  }

  .first-portfolio-progress .progress-bar {
    width: 32%;
    background-color:
      rgba(255, 160, 67, 1);
  }

  .first-portfolio-progress {
    height: 7px !important;
  }

  .second-portfolio-progress {
    height: 7px !important;
  }

  .second-portfolio-progress .progress-bar {
    background-color: rgba(0, 170, 255, 1);
    width: 20%;
  }

  .third-portfolio-progress {
    height: 7px !important;
  }

  .third-portfolio-progress .progress-bar {
    background-color:
      rgba(11, 67, 141, 1);
    width: 60%;
  }

  .fourth-portfolio-progress {
    height: 7px !important;
  }

  .fourth-portfolio-progress .progress-bar {
    background-color:
      rgba(10, 166, 48, 1);
    width: 22%;
  }

  .fifth-portfolio-progress {
    background-color:
      rgba(132, 129, 138, 1);
    height: 7px !important;
  }

  .fifth-portfolio-progress .progress-bar {
    background-color:
      rgba(132, 129, 138, 1);
    width: 10%;
  }

  .epic-chart-select-option {
    width: 120px !important;
    box-shadow: none !important;
  }

  .new-line-chart-btn {
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid #ddd1d1;
    background: transparent;
    color: rgba(137, 80, 218, 1);
  }

  .new-statistics-chart-btn {
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid #ddd1d1;
    background: transparent;
  }

  /* .rgba(132, 129, 138, 1) */
  /* rgba(11, 67, 141, 1) */
  /* <============profile================> */



  .portfolio-whl-nav-tabs .nav-link {
    border: 1px solid grey !important;
    border-radius: 0px !important;
    padding: 5px 15px !important;
    font-size: 13px;
  }

  .portfolio-nav-home-tab {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .portfolio-whl-table-1 .table thead th {
    font-weight: normal;
    color: grey !important;
    font-size: 13px;
  }

  .portfolio-whl-table-1 .table tbody td {
    font-weight: normal;

    font-size: 13px;
    vertical-align: middle;
  }

  .portfolio-whl-table-1 .table-values-rows td {
    padding: 10px 10px;
  }

  .portfolio-whl-table-1 .table-bal-ctn {
    text-align: end !important;
  }

  .portfolio-whl-table-1 .table-bal-Price {
    text-align: center !important;
  }

  .bt-coin-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .portfolio-arr-img {
    width: 15px !important;
    height: 15px !important;
    color: green;
  }

  .portfolio-arr-img-001 {
    width: 15px !important;
    height: 15px !important;
    color: red;
    transform: rotate(90deg);
  }

  .bitcoin-per-point {
    color: green;
  }

  .bitcoin-per-point-red {
    color: red;
  }

  .right-square-arrow {
    color: red;

  }

  .portfolio-arr-img-red {
    width: 15px !important;
    height: 15px !important;
    background-color: red;

  }

  .table-values {
    color: black !important;
  }

  .portfolio-nav-home-tab {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  .portfolio-chart {
    margin-top: 20px;
  }

  .new-portfolio-button-points {
    background-color: white !important;
    border-radius: 10px;
  }

  .epic-chart-select-option {
    font-size: 12px !important;
  }

  .epic-super-script-num {
    color: rgba(10, 166, 48, 1);
  }

  .epic-super-script-tot-num {
    color: rgba(132, 129, 138, 1);
    font-size: 13px;

  }

  .epic-port-num {
    font-size: 19px;
  }

  .port-top-num-sec {
    position: relative;
    top: 25px;
  }

  .portfolio-none-border-sec th {
    border-bottom: none;
  }

  .new-adding-bit-coin {
    font-weight: 500;
  }

  .portfolio-horizontal-lines {
    color: grey !important;
  }

  .apex-new-link-40 {
    padding: 5px 20px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(31, 123, 244, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .apex-new-charts-40 {
    padding: 5px 20px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .apex-line-chart-link-01 {
    padding: 5px 20px;
    font-size: 16px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(31, 123, 244, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .apex-line-chart-link-02 {
    padding: 5px 20px;
    font-size: 16px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(31, 123, 244, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .apex-new-line-chart-link-01 {
    padding: 5px 20px;
    font-size: 16px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(31, 123, 244, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .apex-new-line-chart-link-02 {
    padding: 5px 20px;
    font-size: 16px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: red;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .aaa-01 {
    padding: 6px 15px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(137, 80, 218, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .aaa-02 {
    padding: 6px 15px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .alt-apex-week-selector {
    width: 160px !important;
    font-size: 12px !important;

  }

  .apex-new-percentage-chart {
    color: green;
  }

  .apex-dollar-btn {
    color: grey;
    font-size: 16px;
    font-weight: bold;
  }

  .apex-new-row-numbers {
    color: rgba(32, 32, 32, 1);
    font-weight: 600;
    font-size: 22px;
  }

  .transaction-form-select-01 {
    width: 140px !important;

    font-size: 10px !important;
    color: grey;
    background-color: white;
    border: none !important;
  }

  /* <======================================> */
  /* <==========transaction=============> */
  .transaction-icon-img {
    width: 35px;
    height: auto;
  }

  /* .transaction-new-tables .new-mark-relegious-01 {
    margin-bottom: 20px !important;
  } */

  .transaction-new-tables .new-mark-relegious-01 {
    padding: 10px 0px;
  }

  /* .transaction-new-tables .table>:not(caption)>*>* {
  padding: .5rem 1.8rem;
} */
  .transaction-new-tables .transaction-table-td-00 {
    background: none !important;
  }

  .transaction-table-td-01 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    vertical-align: middle;
    font-size: 13px;
  }

  .transaction-table-td-00 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .transaction-new-tables .table tr th {
    font-weight: normal;
    font-size: 10px;
    color: grey
  }

  .transaction-table-td-02 {
    color: grey !important;
  }

  .table-row-padding {
    padding: 10px 0 !important;
  }

  .transaction-table-td-03:nth-child(2) {
    background-color: red !important;
    padding: 10px !important;
  }

  .complete-cancelled-btn {
    border-radius: 5px;
  }

  .new-mark-relegious-01 {
    padding-bottom: 15px;
    margin-bottom: -15px;
  }

  table {
    border-spacing: 0 10px;
  }

  .transaction-table-td-01 {
    margin-bottom: 10px;
  }

  .transaction-table-full-01 {
    border-radius: 10px;

  }

  .transaction-table-full-00 {
    border-bottom: 1px solid #e3dbdb00;
  }

  .transaction-table-full-00 {
    border-bottom: 1px solid white;
  }

  .new-table-height-00 {
    width: 10%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .new-table-height-01 {
    width: 25%;
  }

  .new-table-height-02 {
    width: 15%;
  }

  .new-table-height-03 {
    width: 10%;
  }

  .new-table-height-04 {
    width: 30%;
  }

  .new-table-height-05 {
    width: 15%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .table-flordy-images-01 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .tot-transaction-process-img .form-select {
    background-image: url("../../assets/images/select-curve-img.png") !important;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 10px;

  }

  .transaction-table-full-00 {
    border-bottom: 1px solid #e3dbdb00;
  }
  .business .link-size{
    font-size: 14px;
  }

  /* .transaction-table-full-01{
  border-collapse: collapse;
  border-spacing:15px 0px;
} */
  /* .transaction-table-td-00{
  padding-top: 15px!important;
  padding-bottom: 15px!important;
} */
  /* <=======================================> */
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1024px) {


  .epic-chart-sec-01 {
    border-radius: 8px;

  }

  .epic-rounds-up {
    color: rgba(137, 80, 218, 1);
    font-size: 18px;
  }

  .epic-rounds-help {
    color: rgba(137, 80, 218, 1);
    font-size: 18px;
  }

  .rnds-up-money {
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rnds-up-money .css-wmy1p7-ReactDropdownSelect {
    border: 1px solid grey !important;
    border-radius: 6px;
  }

  .rnds-up-money-box {
    background: #eae3e3bd;
  }

  .add-to-spend-01 {
    border-top: 1px solid #eae3e3bd;
    border-bottom: 1px solid #eae3e3bd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
  }

  .new-epic-cash-btn {
    background: transparent;
    border: 1px solid rgba(137, 80, 218, 1);
    color: rgba(137, 80, 218, 1);
    border-radius: 8px;
    padding: 10px;
  }

  .epic-ref-ctn {
    color: rgba(137, 80, 218, 1);
  }

  .epic-ref-ctn-num {
    font-size: 50px;
    color: rgba(137, 80, 218, 1);
  }

  .epic-share-full-ctn {
    font-size: 12px;
    color: #786767bd;
    text-align: center;
    padding: 10px;
  }

  .epic-invite-frd-02 {
    background: var(--background-color-1) !important;
    padding: 12px;
    border: 0;
    border-radius: 6px;
    color: white;
    font-size: 14px;
  }

  .epic-invite-frd-03 {

    padding: 12px;
    border: 0;
    border-radius: 6px;
    color: rgba(137, 80, 218, 1);
    font-size: 14px;
    background: transparent;
    border: 1px solid rgba(137, 80, 218, 1);
  }

  .epic-tot-rempic-office {
    background: #eae3e3bd;
    border-radius: 6px;
  }

  .united-state-ctn-01 {
    color: rgba(137, 80, 218, 1);
    font-size: 15px;
  }

  .epic-tkn-num-01 {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .epic-tkn-num-02 {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .epic-fraud-01 {
    color: #554848bd;
    font-size: 14px;
  }

  .epic-fraud-new-child {
    color: black;
  }

  .epic-customer-care-01 {
    font-size: 12px;
    color: #554848bd;
  }

  .full-mail-ctn-01 {
    border-top: 1px solid #eae3e3bd;

  }

  .new-logo-rempic-01 {
    width: 100px;
    height: 70px;
    object-fit: contain;
  }

  .new {
    position: absolute;
    top: 65px;
  }

  .epic-usa-office {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }

  .epic-tkn-num-01 {
    font-size: 13px;
  }

  .epic-tkn-full-num-01 {
    font-size: 13px;
  }

  .epic-fraud-01 {
    font-size: 13px;
  }

  .new-remic-nav {
    display: flex !important;
    justify-content: space-around !important;
  }

  .epic-tab-mail-01 {
    font-size: 13px;
  }

  .new-united-ss {
    display: flex;
    align-items: center !important;
  }

  .epic-tab-mail-num-00 {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;

  }

  .epic-united-sec-01 {
    border-top: 1px solid #eae3e3bd;
  }

  .epic-helps-support {
    color:
      rgba(137, 80, 218, 1);
    font-size: 18px;
  }



  .new-epic-usa-off,
  .new-epic-uk-off {
    font-size: 12px;
    text-align: center;
  }

  .rempic-box-office {
    background: #f6f2f2;
    border-radius: 8px;
  }

  .united-office {
    color:
      rgba(137, 80, 218, 1);
    font-size: 13px;
  }

  .epic-fraud-ctn {
    color: grey;
  }

  .epic-united-states-whl-001 .nav-pills .nav-link {
    border-radius: 10px;
    padding: 2px !important;
  }

  .epic-united-states-whl-001 .nav-pills .nav-link.active {
    background: var(--background-color-1);
  }

  .epic-chart-sec-01 .form-check-input:checked {
    padding: 9px;
    box-shadow: none !important;
    border: none !important;
  }

  .new-multiply-btn {
    width: 100px !important;
    background: transparent !important;
    border: 1px solid grey !important;
  }

  .epic-chart-rounded-01 {
    background: #f2ebeb !important;
  }

  .epic-chart-rounded-01 {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .epic-representation-box {
    height: 80px;
    background-color: white;
  }

  .new-logo-rempic-01 {
    background: white !important;
    border-radius: 8px !important;
  }

  .epic-total-points-54 {
    position: absolute;
    top: 41%;
    left: 37%;
    font-size: 10px;
    text-align: center;
    color: grey;
  }

  .epic-total-number {
    color: var(--color-1);
    font-size: 10px;
  }

  .epic-form-select-01 {
    background-color: #eae3e3bd !important;
  }

  .epic-form-select-01 {
    font-size: 12px !important;
    width: 100px !important;
    border: 1px solid #978c8c !important;
    color: black !important;
    box-shadow: none !important;
  }

  .epic-total-points-540 {
    left: calc(50% - 40px);
    top: calc(50% - 20px);
    font-size: 12px;
  }

  .epic-chart-sec-00 .apexcharts-legend {
    position: absolute;
    left: auto;
    top: 24px;
    right: 6% !important;
  }

  /* <=====================> */
  .epic-total-points-05 {
    position: relative;
    margin-top: -40% !important;
  }

  /* <=======================> */
  /* <============profile================> */

  .portfolio-whl-nav-tabs .nav-link {
    border: 1px solid grey !important;
    border-radius: 0px !important;
    padding: 5px 15px !important;
    font-size: 13px;
  }

  .portfolio-nav-home-tab {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .portfolio-whl-table-1 .table thead th {
    font-weight: normal;
    color: grey !important;
    font-size: 13px;
  }

  .portfolio-whl-table-1 .table tbody td {
    font-weight: normal;

    font-size: 13px;
    vertical-align: middle;
  }

  .portfolio-whl-table-1 .table-values-rows td {
    padding: 10px 10px;
  }

  .portfolio-whl-table-1 .table-bal-ctn {
    text-align: end !important;
  }

  .portfolio-whl-table-1 .table-bal-Price {
    text-align: center !important;
  }

  .bt-coin-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .portfolio-arr-img {
    width: 15px !important;
    height: 15px !important;
    color: green;
  }

  .portfolio-arr-img-001 {
    width: 15px !important;
    height: 15px !important;
    color: red;
    transform: rotate(90deg);
  }

  .bitcoin-per-point {
    color: green;
  }

  .bitcoin-per-point-red {
    color: red;
  }

  .right-square-arrow {
    color: red;

  }

  .portfolio-arr-img-red {
    width: 15px !important;
    height: 15px !important;
    background-color: red;

  }

  .table-values {
    color: black !important;
  }

  .portfolio-nav-home-tab {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  .portfolio-chart {
    margin-top: 20px;
  }

  .new-portfolio-button-points {
    background-color: white !important;
    border-radius: 30px !important;
  }

  .epic-chart-select-option {
    font-size: 12px !important;
  }

  .epic-super-script-num {
    color: rgba(10, 166, 48, 1);
  }

  .epic-super-script-tot-num {
    color: rgba(132, 129, 138, 1);
    font-size: 13px;

  }

  .epic-port-num {
    font-size: 19px;
  }

  .first-portfolio-progress .progress-bar {
    width: 32%;
    background-color:
      rgba(255, 160, 67, 1);
  }

  .first-portfolio-progress {
    height: 7px !important;
  }

  .second-portfolio-progress {
    height: 7px !important;
  }

  .second-portfolio-progress .progress-bar {
    background-color: rgba(0, 170, 255, 1);
    width: 20%;
  }

  .third-portfolio-progress {
    height: 7px !important;
  }

  .third-portfolio-progress .progress-bar {
    background-color:
      rgba(11, 67, 141, 1);
    width: 60%;
  }

  .fourth-portfolio-progress {
    height: 7px !important;
  }

  .fourth-portfolio-progress .progress-bar {
    background-color:
      rgba(10, 166, 48, 1);
    width: 22%;
  }

  .fifth-portfolio-progress {
    background-color:
      rgba(132, 129, 138, 1);
    height: 7px !important;
  }

  .fifth-portfolio-progress .progress-bar {
    background-color:
      rgba(132, 129, 138, 1);
    width: 10%;
  }

  .epic-chart-select-option {
    width: 120px !important;
    box-shadow: none !important;
  }

  .new-line-chart-btn {
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid #ddd1d1;
    background: transparent;
    color: rgba(137, 80, 218, 1);
  }

  .new-statistics-chart-btn {
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid #ddd1d1;
    background: transparent;
  }

  .port-tot-line-chart-views {
    display: flex;
  }

  .port-top-num-sec {
    position: relative;
    top: 25px;
  }

  .apex-new-link-40 {
    padding: 5px 20px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(31, 123, 244, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .apex-new-charts-40 {
    padding: 5px 20px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .aaa-01 {
    padding: 6px 15px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(137, 80, 218, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .aaa-02 {
    padding: 6px 15px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .apex-new-percentage-chart {
    color: green;
  }

  .alt-apex-week-selector {
    width: 160px !important;
    font-size: 12px !important;
    /* padding:6px 15px; */
  }

  .apex-dollar-btn {
    color: grey;
    font-size: 16px;

  }

  /* <==========transaction=============> */
  .transaction-icon-img {
    width: 35px;
    height: auto;
  }

  /* .transaction-new-tables .new-mark-relegious-01 {
    margin-bottom: 20px !important;
  } */

  .transaction-new-tables .new-mark-relegious-01 {
    padding: 10px 0px;
    margin-bottom: -10px;
  }

  /* .transaction-new-tables .table>:not(caption)>*>* {
    padding: .5rem 1.8rem;
  } */
  .transaction-new-tables .transaction-table-td-00 {
    background: none !important;
  }

  .transaction-table-td-01 {

    padding-top: 16px !important;
    padding-bottom: 16px !important;
    vertical-align: middle;
    font-size: 13px;
    white-space: nowrap;
  }

  .transaction-table-td-00 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .transaction-new-tables .table tr th {
    font-weight: normal;
    font-size: 10px;
    color: grey
  }

  .transaction-table-td-02 {
    color: grey !important;
  }

  .table-row-padding {
    padding: 10px 0 !important;
  }

  .transaction-table-td-03:nth-child(2) {
    background-color: red !important;
    padding: 10px !important;
  }

  .complete-cancelled-btn {
    border-radius: 5px;
  }

  .transaction-table-full-01 {
    width: 1000px !important;
  }

  .transaction-table-full-00 {
    overflow-x: auto;
  }

  .transaction-table-full-00 {
    border-bottom: 1px solid white;
  }

  .new-table-height-00 {
    width: 10%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .new-table-height-01 {
    width: 25%;
  }

  .new-table-height-02 {
    width: 20%;
  }

  .new-table-height-03 {
    width: 10%;
  }

  .new-table-height-04 {
    width: 20%;
  }

  .new-table-height-05 {
    width: 15%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .table-flordy-images-01 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .tot-transaction-process-img .form-select {
    background-image: url("../../assets/images/select-curve-img.png") !important;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 10px;

  }

  .transaction-table-full-00 {
    border-bottom: 1px solid #e3dbdb00;
  }
  .business .link-size{
    font-size: 14px;
  }

  /* .transaction-table-td-00{
    padding-top: 15px!important;
    padding-bottom: 15px!important;
  } */
  /* <=======================================> */

  /* <======================================> */
}

/* Mobile styles */
@media (max-width: 767px) {



  .united-office {
    color:
      rgba(137, 80, 218, 1);
    font-size: 13px;
  }

  .epic-united-states-whl-001 .nav-pills .nav-link {
    border-radius: 10px;
    padding: 2px !important;
  }

  .epic-united-states-whl-001 .nav-pills .nav-link.active {
    background: var(--background-color-1);
  }

  .epic-chart-sec-01 {
    /* background:white; */
    border-radius: 8px;

  }

  .epic-rounds-up {
    color: rgba(137, 80, 218, 1);
    font-size: 18px;
  }

  .epic-rounds-help {
    color: rgba(137, 80, 218, 1);
    font-size: 18px;
  }

  .rnds-up-money {
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rnds-up-money .css-wmy1p7-ReactDropdownSelect {
    border: 1px solid grey !important;
    border-radius: 6px;
  }

  .rnds-up-money-box {
    background: #eae3e3bd;
  }

  .add-to-spend-01 {
    border-top: 1px solid #eae3e3bd;
    border-bottom: 1px solid #eae3e3bd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
  }

  .new-epic-cash-btn {
    background: transparent;
    border: 1px solid rgba(137, 80, 218, 1);
    color: rgba(137, 80, 218, 1);
    border-radius: 8px;
    padding: 10px;
  }

  .epic-ref-ctn {
    color: rgba(137, 80, 218, 1);
  }

  .epic-ref-ctn-num {
    font-size: 50px;
    color: rgba(137, 80, 218, 1);
  }

  .epic-share-full-ctn {
    font-size: 12px;
    color: #786767bd;
    text-align: center;
    padding: 10px;
  }

  .epic-invite-frd-02 {
    background: var(--background-color-1) !important;
    padding: 12px;
    border: 0;
    border-radius: 6px;
    color: white;
    font-size: 14px;
  }

  .epic-invite-frd-03 {

    padding: 12px;
    border: 0;
    border-radius: 6px;
    color: rgba(137, 80, 218, 1);
    font-size: 14px;
    background: transparent;
    border: 1px solid rgba(137, 80, 218, 1);
  }

  .epic-tot-rempic-office {
    background: #eae3e3bd;
    border-radius: 6px;
  }

  .united-state-ctn-01 {
    color: rgba(137, 80, 218, 1);
    font-size: 15px;
  }

  .epic-tkn-num-01 {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .epic-tkn-num-02 {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .epic-fraud-01 {
    color: #554848bd;
    font-size: 14px;
  }

  .epic-fraud-new-child {
    color: black;
  }

  .epic-customer-care-01 {
    font-size: 12px;
    color: #554848bd;
  }

  .full-mail-ctn-01 {
    border-top: 1px solid #eae3e3bd;

  }

  .new-logo-rempic-01 {
    width: 100px;
    height: 70px;
    object-fit: contain;
  }

  .new {
    position: absolute;
    top: 65px;
  }

  .epic-usa-office {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }

  .epic-tkn-num-01 {
    font-size: 13px;
  }

  .epic-tkn-full-num-01 {
    font-size: 13px;
  }

  .epic-fraud-01 {
    font-size: 13px;
  }

  .new-remic-nav {
    display: flex !important;
    justify-content: space-around !important;
  }

  .epic-tab-mail-01 {
    font-size: 13px;
  }

  .new-united-ss {
    display: flex;
    align-items: center !important;
  }

  .epic-tab-mail-num-00 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
  }

  .epic-united-sec-01 {
    border-top: 1px solid #eae3e3bd;
  }

  .epic-helps-support {
    color:
      rgba(137, 80, 218, 1);
    font-size: 18px;
  }

  .new-epic-usa-off,
  .new-epic-uk-off {
    font-size: 12px;
    text-align: center;
  }

  .rempic-box-office {
    background: #f6f2f2;
    border-radius: 8px;
  }

  .united-office {
    color:
      rgba(137, 80, 218, 1);
    font-size: 13px;
  }

  .epic-fraud-ctn {
    color: grey;
  }

  .new-multiply-btn {
    width: 100px !important;
    background: transparent !important;
    border: 1px solid grey !important;
  }

  .epic-chart-sec-01 .form-check-input:checked {
    background-color: rgba(137, 80, 218, 1) !important;
    padding: 9px;
    box-shadow: none !important;
    border: none !important;
  }

  .epic-chart-rounded-01 {
    background: #f2ebeb !important;
  }

  .epic-chart-rounded-01 {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .epic-representation-box {
    height: 80px;
    background-color: white;
  }

  .united-office {
    color:
      rgba(137, 80, 218, 1);
    font-size: 13px;
  }


  .new-logo-rempic-01 {
    background: white !important;
    border-radius: 8px !important;
  }

  .epic-total-points-54 {
    position: absolute;
    top: 24%;
    left: 24%;
    font-size: 10px;
    text-align: center;
    color: grey;
  }

  .epic-total-number {
    color: var(--color-1);
    font-size: 10px;
  }

  .epic-form-select-01 {
    background-color: #eae3e3bd !important;
  }

  .epic-form-select-01 {
    font-size: 12px !important;
    width: 100px !important;
    border: 1px solid #978c8c !important;
    color: black !important;
    box-shadow: none !important;
  }

  .epic-chart-sec-00 .apexcharts-legend {
    position: absolute;
    left: auto;
    top: 24px;
    right: 6% !important;
  }

  .epic-total-points-54 {
    position: absolute;
    top: 50%;
    left: 26%;
    font-size: 10px;
    text-align: center;
    color: grey;
  }

  /* <============profile================> */

  .portfolio-whl-nav-tabs .nav-link {
    border: 1px solid grey !important;
    border-radius: 0px !important;
    padding: 5px 15px !important;
    font-size: 13px;
  }

  .portfolio-nav-home-tab {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }


  .portfolio-whl-table-1 .table thead th {
    font-weight: normal;
    color: grey !important;
    font-size: 13px;
    white-space: nowrap;
  }

  .portfolio-whl-table-1 .table tbody td {
    font-weight: normal;

    font-size: 13px;
    vertical-align: middle;
  }

  .portfolio-whl-table-1 .table-values-rows td {
    padding: 10px 10px;
  }

  .portfolio-whl-table-1 .table-bal-ctn {
    text-align: end !important;
  }

  .portfolio-whl-table-1 .table-bal-Price {
    text-align: center !important;
  }

  .bt-coin-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .portfolio-arr-img {
    width: 15px !important;
    height: 15px !important;
    color: green;
  }

  .portfolio-arr-img-001 {
    width: 15px !important;
    height: 15px !important;
    color: red;
    transform: rotate(90deg);
  }

  .bitcoin-per-point {
    color: green;
  }

  .bitcoin-per-point-red {
    color: red;
  }

  .right-square-arrow {
    color: red;

  }

  .portfolio-arr-img-red {
    width: 15px !important;
    height: 15px !important;
    background-color: red;

  }

  .table-values {
    color: black !important;
  }

  .portfolio-nav-home-tab {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  .portfolio-chart {
    margin-top: 20px;
  }

  .new-portfolio-button-points {
    background-color: white !important;
    border-radius: 10px;
  }

  .epic-chart-select-option {
    font-size: 12px !important;
  }

  .epic-super-script-num {
    color: rgba(10, 166, 48, 1);
  }

  .epic-super-script-tot-num {
    color: rgba(132, 129, 138, 1);
    font-size: 13px;

  }

  .epic-port-num {
    font-size: 19px;
  }

  .first-portfolio-progress .progress-bar {
    width: 32%;
    background-color:
      rgba(255, 160, 67, 1);
  }

  .first-portfolio-progress {
    height: 7px !important;
  }

  .second-portfolio-progress {
    height: 7px !important;
  }

  .second-portfolio-progress .progress-bar {
    background-color: rgba(0, 170, 255, 1);
    width: 20%;
  }

  .third-portfolio-progress {
    height: 7px !important;
  }

  .third-portfolio-progress .progress-bar {
    background-color:
      rgba(11, 67, 141, 1);
    width: 60%;
  }

  .fourth-portfolio-progress {
    height: 7px !important;
  }

  .fourth-portfolio-progress .progress-bar {
    background-color:
      rgba(10, 166, 48, 1);
    width: 22%;
  }

  .fifth-portfolio-progress {
    background-color:
      rgba(132, 129, 138, 1);
    height: 7px !important;
  }

  .fifth-portfolio-progress .progress-bar {
    background-color:
      rgba(132, 129, 138, 1);
    width: 10%;
  }

  .epic-chart-select-option {
    width: 120px !important;
    box-shadow: none !important;
  }

  .new-line-chart-btn {
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid #ddd1d1;
    background: transparent;
    color: rgba(137, 80, 218, 1);
  }

  .new-statistics-chart-btn {
    font-size: 12px;
    padding: 5px 10px;
    border: 1px solid #ddd1d1;
    background: transparent;
  }

  .port-tot-line-chart-views {
    display: flex;
  }

  .portfolio-whl-table-1 {
    overflow-x: auto !important;
  }

  .port-top-num-sec {
    position: relative;
    top: 0px;
  }

  .port-new-bitcoin-20 {
    font-size: 10px;
  }

  .new-img-btc-01 {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }

  .port-top-num-sec {
    position: relative;

    top: -10px;
    text-align: center;

    left: 40px;
  }

  .new-img-btc-001 {
    width: 30px;
    margin-right: 10px;
  }

  .apex-new-link-40 {
    padding: 5px 20px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(31, 123, 244, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .apex-new-charts-40 {
    padding: 5px 20px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .aaa-01 {
    padding: 4px 10px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-right: none;
    color: rgba(137, 80, 218, 1);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .aaa-02 {
    padding: 4px 10px;
    font-size: 12px;
    background: transparent;
    border: 1px solid #d9cdcd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .alt-apex-week-selector {
    width: 90px !important;
    font-size: 12px !important;
  }

  .portfolio-whl-table-1 {
    overflow-x: auto;
  }

  .apex-chart-customize-table {
    width: 900px;
  }

  .apex-new-percentage-chart {
    color: green;
  }

  .apex-dollar-btn {
    color: grey;
    font-size: 12px;

  }

  /* <==========transaction=============> */
  .transaction-icon-img {
    width: 35px;
    height: auto;
  }

  /* .transaction-new-tables .new-mark-relegious-01 {
    margin-bottom: 20px !important;
  } */

  .transaction-new-tables .new-mark-relegious-01 {
    padding: 10px 0px;
    margin-bottom: -10px;
  }

  .transaction-new-tables .table>:not(caption)>*>* {
    padding: .5rem 1.8rem;
  }

  .transaction-new-tables .transaction-table-td-00 {
    background: none !important;
  }

  .transaction-table-td-01 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    vertical-align: middle;
    font-size: 13px;
    white-space: nowrap;
  }

  .transaction-table-td-00 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .transaction-new-tables .table tr th {
    font-weight: normal;
    font-size: 10px;
    color: grey
  }

  .transaction-table-td-02 {
    color: grey !important;
  }

  .table-row-padding {
    padding: 10px 0 !important;
  }

  .transaction-table-td-03:nth-child(2) {
    background-color: red !important;
    padding: 10px !important;
  }

  .complete-cancelled-btn {
    border-radius: 5px;
  }

  .transaction-table-full-01 {
    width: 1000px !important;

  }

  .transaction-table-full-00 {
    overflow-x: auto;
  }

  .new-dash-head-1 {
    font-size: 12px;
  }

  .alt-apex-week-selector {
    width: 115px !important;
  }

  .transaction-table-full-00 {
    border-bottom: 1px solid white;
  }

  .new-table-height-00 {
    width: 10%;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .new-table-height-01 {
    width: 25%;
  }

  .new-table-height-02 {
    width: 20%;
  }

  .new-table-height-03 {
    width: 10%;
  }

  .new-table-height-04 {
    width: 20%;
  }

  .new-table-height-05 {
    width: 15%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .table-flordy-images-01 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .tot-transaction-process-img .form-select {
    background-image: url("../../assets/images/select-curve-img.png") !important;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 10px !important;

  }

  .transaction-table-full-00 {
    border-bottom: 1px solid #e3dbdb00;
  }
  .business .link-size{
    font-size: 11px;
  }

  /* .transaction-table-td-00{
    padding-top: 15px!important;
    padding-bottom: 15px!important;
  } */
  /* <=======================================> */
  /* <==============transaction==================> */


  /* <================================================> */
  /* <======================================> */


}


/* #e3dbdb00 */
.form-check-input{
  box-shadow: none !important;
}


/* <===========Business-form===============> */
.custom {

  padding: 100px 0px 0px 0px;
  font-weight: 300;
  font-size: 14px;
}
.custom-one{
  padding: 10px;
  font-weight: 300;
  font-size: 14px;
}

.business-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--color-1);
}

.option-css {
  color: var(--color-1);
}

.business-names {
  font-weight: 300;
  font-size: 14px;
}

.business-input-box {
  padding: .375rem .75rem;
  width: 100%;
}

.whl-business-form-box {
  background-color: white;
  border-radius: 15px;
  /* height: 530px;
  overflow-y: scroll; */

}

.business-input-box {
  border: 1px solid #dddbdb;
  border-radius: 5px;
}

.touch-btn {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
}

.business-dwn-arrow {
  font-size: 25px;
  color: white;
}

.business-press-btn {
  position: fixed;
  width: 50px;
  height: 50px;

  right: 10px;
  bottom: 20px;
}

.business-down-arrow {
  width: 100%;
  height: 100%;
  background: linear-gradient(90.9deg, #B5379F 3.03%, #4E49B9 94.33%);
  border-radius: 10px;
  border: none;
}

.new-dwn-arrows {
  font-size: 30px;
  color: white;
}

.business-list {
  list-style: none;
}

/* .new-01{
  width:40px;
  height:20px;
  background-color: red;
}
.new-01-01{
  width:40px;
  background-color: red;
} */
.business-input-group-box {
  font-size: 14px !important;
  border: 1px solid darkgrey !important;
  box-shadow: none !important;
}

.business-input-group-box::placeholder {
  font-size: 13px;
  color: rgb(161, 161, 161);
}

.business-input-headings-one {
  font-weight: 500 !important;
  font-size: 17px !important;
}

.business-input-headings {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.whole-business-form .form-check-input:checked {
  background-color: #978c8c !important;
  border: 1px solid #978c8c !important;
}

.whole-business-form .form-check-input:checked:focus {
  box-shadow: none !important;
}

/* .business-input-headings {
  font-weight: normal !important;
} */

.whole-business-form .form-check-input[type=checkbox] {
  border: 1px solid darkgrey !important;
}

.whole-business-form .form-check-input[type=radio] {
  border: 1px solid darkgrey !important;
}

@media (max-width: 991px) {
  .whl-business-form-box {
    background-color: white;
    border-radius: 15px;
    /* height: 100vh;
    overflow-y: scroll; */
  }

  .whole-business-form .form-check-input:checked {
    background-color: #978c8c !important;
    border: 2px solid #978c8c !important;
  }

  .whole-business-form .form-check-input:checked:focus {
    box-shadow: none !important;
  }

  /* .business-input-headings {
    font-weight: normal !important;
  } */

  .whole-business-form .form-check-input[type=checkbox] {
    border: 2px solid darkgrey !important;
  }

  .whole-business-form .form-check-input[type=radio] {
    border: 2px solid darkgrey !important;
  }

  .business-input-group-box {
    font-size: 15px !important;
    border: 2px solid darkgrey !important;
  }
}

.business .form-control:focus{
  box-shadow: none;
  border: 1px solid darkgrey;
}
#businessform .d-send-btn-1:hover{
  background: #0a53be;
}
.Legalentity .bef-b-login-1:hover{
  background: #4E49B9;
}
.Individual .bef-b-login-1:hover{
  background: #4E49B9;
}
.ubo .bef-b-login-1:hover{
  background: #4E49B9;
}
.req-doc .form-label{
  cursor: pointer;
}



@media (max-width: 767px) {
  .req-doc .form-control{
    width: 100%;
  }
}

@media (min-width: 768px) {
  .req-doc .form-control{
    width: 300px;
  }
}